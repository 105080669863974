<template>
  <div>
    <search-block title="распорядительных документов">
      <el-row :gutter="10">
        <el-col :span="22">
          <el-input
            @input="searchDocument"
            v-model="search"
            placeholder="Поиск по наименованию"
          ></el-input>
        </el-col>
        <el-col :span="2">
          <el-button @click="showAddModal" type="success">Добавить</el-button>
        </el-col>
      </el-row>
    </search-block>
    <el-table stripe border :data="listAdministrativeDocument.content">
      <el-table-column label="Наименование" prop="name">
        <template slot-scope="item">
          {{ item.row.number }} {{ item.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="Номер" prop="number"></el-table-column>
      <el-table-column label="Дата" prop="date"></el-table-column>
      <el-table-column label="Файл">
        <template slot-scope="item">
          <el-button
            v-if="item.row.filePath !== null"
            type="text"
            @click="download(item.row.filePath)"
            style="margin-left: 10px"
          >
            {{ getFileName(item.row.filePath) }}
          </el-button>
          <p v-else>{{ getFileName(item.row.filePath) }}</p>
        </template>
      </el-table-column>
      <el-table-column width="150" label="">
        <template slot-scope="item">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="width: 100%"
                @click="edit(item.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                size="mini"
                @click="deleteDoc(item.row)"
                style="width: 100%; margin-top: 5px; margin-left: 0"
                type="danger"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="listAdministrativeDocument.size"
      :current-page.sync="listAdministrativeDocument.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="listAdministrativeDocument.totalElements"
    >
    </el-pagination>

    <custom-modal
      title="Добавить распорядительный документ"
      :visible="showModal === 'addAdministrativeDocument'"
    >
      <administrative-document-add></administrative-document-add>
    </custom-modal>

    <custom-modal
      title="Изменить распорядительный документ"
      :visible="showModal === 'updateAdministrativeDocument'"
    >
      <administrative-document-edit
        :selected-document="selectedDocument"
      ></administrative-document-edit>
    </custom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchBlock from "@/components/searchBlock";
import CustomModal from "@/components/customModal";
import AdministrativeDocumentAdd from "@/components/ecologist/classifiers/administrativeDocument/administrativeDocumentAdd";
import notification from "@/mixins/notification";
import AdministrativeDocumentEdit from "@/components/ecologist/classifiers/administrativeDocument/administrativeDocumentEdit";

export default {
  name: "administrativeDocumentTable",
  components: {
    AdministrativeDocumentEdit,
    AdministrativeDocumentAdd,
    CustomModal,
    SearchBlock,
  },
  mixins: [notification],
  data() {
    return {
      search: null,
      selectedDocument: {},
    };
  },

  computed: {
    ...mapGetters({
      listAdministrativeDocument: "GETLISTADMINISTRATIVEDOCUMENT",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    download(filePath) {
      console.log(filePath);
      this.$store.dispatch("downloadFile", filePath);
    },

    getFileName(filePath) {
      return filePath !== null
        ? filePath.slice(filePath.lastIndexOf("\\") + 1)
        : "Документ не загружен";
    },

    handleSizeChange(val) {
      this.getAllAdministrativeDocument({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getAllAdministrativeDocument({
        page: val - 1,
        size: this.listAdministrativeDocument.size,
      });
    },

    showAddModal() {
      this.$store.dispatch("setShowModal", "addAdministrativeDocument");
    },

    searchDocument(query) {
      this.$store.dispatch("searchAdministrativeDocument", {
        query: query,
        page: 0,
        size: 20,
      });
    },

    edit(item) {
      console.log(item);
      this.selectedDocument = item;
      this.$store.dispatch("setShowModal", "updateAdministrativeDocument");
    },

    deleteDoc(item) {
      this.confirm(
        "Удалить документ",
        "Вы действительно хотите удалить документ?",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store
          .dispatch("deleteAdministrativeDocument", item.docId)
          .then((response) => {
            console.log(response);
            this.notification("Успешно", "Документ удален", "success");
            this.getAllAdministrativeDocument({
              page: this.listAdministrativeDocument.number,
              size: this.listAdministrativeDocument.size,
            });
          });
      });
      console.log(item);
    },

    getAllAdministrativeDocument(data) {
      this.$store.dispatch("getAllAdministrativeDocument", data);
    },
  },

  mounted() {
    this.getAllAdministrativeDocument({ page: 0, size: 20 });
  },
};
</script>

<style scoped></style>
