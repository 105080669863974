<template>
  <div>
    <el-form
      :model="addAdministrativeDocumentForm"
      ref="formedForm"
      :rules="rules"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="Наименование" prop="name">
            <el-input v-model="addAdministrativeDocumentForm.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Номер" prop="number">
            <el-input v-model="addAdministrativeDocumentForm.number">
              <template slot="prepend">№</template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Дата" prop="date">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd"
              v-model="addAdministrativeDocumentForm.date"
              type="date"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-upload
            style="text-align: center"
            drag
            :limit="1"
            :on-change="changeFile"
            :auto-upload="false"
            v-model="addAdministrativeDocumentForm.file"
            action=""
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              Перетащите файл сюда или <em>нажмите для загрузки</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              .pdf, .xls/.xlsx, .img, .jpg, .png, .doc/.docx файлы размером не
              больше 20 Мб
            </div>
          </el-upload>
        </el-col>
      </el-row>
      <el-button type="success" @click="save">Сохранить</el-button>
    </el-form>
  </div>
</template>

<script>
import notification from "@/mixins/notification";

export default {
  name: "administrativeDocumentAdd",
  mixins: [notification],
  data() {
    return {
      addAdministrativeDocumentForm: {},
      file: {},
      rules: {
        name: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        number: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        date: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    changeFile(file, fileList) {
      console.log(fileList);
      this.file = file.raw;
    },

    save() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          let document = new FormData();
          document.append("name", this.addAdministrativeDocumentForm.name);
          document.append(
            "number",
            this.addAdministrativeDocumentForm.number
          );
          document.append("date", this.addAdministrativeDocumentForm.date);
          document.append("file", this.file);

          this.$store
            .dispatch("createAdministrativeDocument", document)
            .then((response) => {
              console.log(response);
              this.notification("Успешно", "Документ создан", "success");
              this.$store.dispatch("getAllAdministrativeDocument", {
                page: 0,
                size: 20,
              });
              this.$store.dispatch("setShowModal", null);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped></style>
