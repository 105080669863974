<template>
  <div>
    <h2>Справочник распорядительных документов</h2>

    <administrative-document-table></administrative-document-table>
  </div>
</template>

<script>
import AdministrativeDocumentTable from "@/components/ecologist/classifiers/administrativeDocument/administrativeDocumentTable";
export default {
  name: "administrativeDocumentMain",
  components: { AdministrativeDocumentTable },
};
</script>

<style scoped></style>
