import { render, staticRenderFns } from "./administrativeDocumentMain.vue?vue&type=template&id=91c210f2&scoped=true&"
import script from "./administrativeDocumentMain.vue?vue&type=script&lang=js&"
export * from "./administrativeDocumentMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91c210f2",
  null
  
)

export default component.exports