<template>
  <div>
    <administrative-document-main></administrative-document-main>
  </div>
</template>

<script>
import AdministrativeDocumentMain from "@/components/ecologist/classifiers/administrativeDocument/administrativeDocumentMain";
export default {
  name: "administrativeDocumentMainView",
  components: { AdministrativeDocumentMain },
};
</script>

<style scoped></style>
